































import { computed, defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import i18n from '@/plugins/i18n';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcCol,
    GcForm,
    GcRow,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);

    const showBackButton = () =>
      props.step && props.steps && props.steps.indexOf(props.step) > 0;

    const showRequiredHint = computed<boolean>(
      (): boolean =>
        process.env.VUE_APP_SHOW_REQUIRED_HINT === 'true' &&
        Boolean(
          props.step?.fields &&
            props.step?.fields.some((field) => field.required === true),
        ),
    );

    const onTransition = (data) => {
      emit('transition', data);
    };

    const vehicleType = props.assistantContext.vehicleClass.class[0];
    const isCheckSuccess: boolean =
      props.assistantContext.availabilityCheck.driveType.includes(
        'purelyElectric',
      ) &&
      props.assistantContext.vehicleRegistration.registration.includes('yes');

    const stepTitle = i18n.t(
      `check.${isCheckSuccess ? 'success' : 'failure'}.title`,
    ) as string;

    return {
      formData,
      formValid,
      onNext,
      onPrev,
      onTransition,
      showBackButton,
      showRequiredHint,
      isCheckSuccess,
      stepTitle,
      vehicleType,
    };
  },
});
