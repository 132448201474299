/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  start: {
    buttonText: 'Ich möchte gerne die THG-Prämie nutzen!',
    description:
      'Mit Ihrer Entscheidung für ein Elektro-Fahrzeug haben Sie eine gute Wahl getroffen, denn Sie leisten damit einen entscheidenden Beitrag zur Energiewende. Neben den bekannten Herstellerrabatten und staatlichen Förderungen, können Sie sich ab sofort eine weitere attraktive Prämie sichern.',
    title: 'THG-Quote',
    step: {
      label: 'Start',
    },
  },
  availabilityCheck: {
    title: 'Erhalten auch Sie die THG-Prämie?',
    step: {
      label: 'Antriebsart',
    },
    formFields: {
      driveType: {
        label: 'Welche Antriebsart hat Ihr E-Auto?',
        options: {
          purelyElectric: {
            label: 'Rein elektrisch',
          },
          hybrid: {
            label: 'Hybrid',
          },
        },
      },
    },
  },
  vehicleClass: {
    title: 'Welche Fahrzeugklasse ist im Fahrzeugschein eingetragen?',
    step: {
      label: 'Fahrzeugklasse',
    },
    formFields: {
      class: {
        label: 'Fahrzeugklasse',
        description:
          'Diese Angabe finden Sie im Feld J der Zulassungsbescheinigung Teil 1.',
        helperDialog: {
          title: 'Fahrzeugklassen',
          html: '<p><strong>Klasse M1</strong><br>Fahrzeuge zur Personenbeförderung mit höchstens acht Sitzplätzen außer dem Fahrersitz (umgangssprachlich Automobile und Wohnmobile).</p><p><strong>Klasse N1</strong><br>Für die Güterbeförderung ausgelegte und gebaute Kraftfahrzeuge mit einer zulässigen Gesamtmasse bis zu 3,5 Tonnen.</p><p><strong>Klasse M3</strong><br>Fahrzeuge zur Personenbeförderung mit mehr als acht Sitzplätzen außer dem Fahrersitz und einer zulässigen Gesamtmasse von mehr als 5 Tonnen.</p><p><strong>Klassen M, L</strong><br>Für die Fahrzeugklassen M und L ist unter bestimmten Voraussetzungen eine Prämie nach THG möglich. Eine gesonderte Überprüfung ist jedoch notwendig.</p>',
        },
        options: {
          m1: {
            label: 'M1',
          },
          n1: {
            label: 'N1',
          },
          m3: {
            label: 'M3',
          },
          ml: {
            label: 'M, L',
          },
        },
      },
    },
  },
  customer: {
    title: 'Kunde',
    step: {
      label: 'Kunde',
    },
    formFields: {
      isCustomer: {
        label: 'Sind Sie Kunde der Städtischen Werke AG?',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
    },
  },
  check: {
    title: 'Verfügbarkeits-Check',
    step: {
      label: 'Verfügbarkeits-Check',
    },
    success: {
      title: 'Herzlichen Glückwunsch!',
      content: {
        m1: '<p>Sie haben den Anspruch auf einen Zuschuss nach THG-Quote. Ihre Prämie wird 275 € inkl. Mehrwertsteuer betragen.</p><p><strong>Wie erhalte ich nun meinen Zuschuss?</strong></p><p>Wir registrieren für Sie mit Hilfe der im nächsten Schritt des Formulars erfassten Angaben Ihre THG-Quote und lassen diese durch das Bundesumweltamt anerkennen. Die so anerkannten CO2-Einsparungen können dann an quotenpflichtige Unternehmen – wie zum Beispiel Energieunternehmen oder Mineralölfirmen – verkauft werden. Der Erlös aus diesem Verkauf finanziert dann Ihre Prämie. Bitte beachten Sie, dass Sie Ihre Prämie ab einem bestimmten Betrag selbst zu versteuern. Weitere Infos dazu finden Sie in den FAQ.</p>',
        n1: '<p>Sie haben den Anspruch auf einen Zuschuss nach THG-Quote. Ihre jährliche Prämie wird mindestens 400 € inkl. Mehrwertsteuer betragen. Die tatsächliche Höhe hängt jedoch noch von weiteren Angaben zu Ihrem Fahrzeug ab. Bitte füllen Sie dazu den folgenden Antrag aus. Nach der Prüfung erhalten Sie die Rückmeldung über Ihre individuelle Prämie.</p><p><strong>Wie erhalte ich nun meinen Zuschuss?</strong></p><p>Wir registrieren für Sie mit Hilfe der im nächsten Schritt des Formulars erfassten Angaben Ihre THG-Quote und lassen diese durch das Bundesumweltamt anerkennen. Die so anerkannten CO2-Einsparungen können dann an quotenpflichtige Unternehmen – wie zum Beispiel Energieunternehmen oder Mineralölfirmen – verkauft werden. Der Erlös aus diesem Verkauf finanziert dann Ihre Prämie. Bitte beachten Sie, dass Sie Ihre Prämie ab einem bestimmten Betrag selbst zu versteuern. Weitere Infos dazu finden Sie in den FAQ.</p>',
        m3: '<p>Sie haben den Anspruch auf einen Zuschuss nach THG-Quote. Ihre jährliche Prämie wird mindestens 9.500 € inkl. Mehrwertsteuer betragen. Die tatsächliche Höhe hängt jedoch noch von weiteren Angaben zu Ihrem Fahrzeug ab. Bitte füllen Sie dazu den folgenden Antrag aus. Nach der Prüfung erhalten Sie die Rückmeldung über Ihre individuelle Prämie.</p><p><strong>Wie erhalte ich nun meinen Zuschuss?</strong></p><p>Wir registrieren für Sie mit Hilfe der im nächsten Schritt des Formulars erfassten Angaben Ihre THG-Quote und lassen diese durch das Bundesumweltamt anerkennen. Die so anerkannten CO2-Einsparungen können dann an quotenpflichtige Unternehmen – wie zum Beispiel Energieunternehmen oder Mineralölfirmen – verkauft werden. Der Erlös aus diesem Verkauf finanziert dann Ihre Prämie. Bitte beachten Sie, dass Sie Ihre Prämie ab einem bestimmten Betrag selbst zu versteuern. Weitere Infos dazu finden Sie in den FAQ.</p>',
        ml: '<p>Für die Fahrzeugklassen M und L ist unter bestimmten Voraussetzungen eine Prämie nach THG möglich. Bitte nutzen Sie den folgenden Dialog dazu, weitere Angaben zu Ihrem Fahrzeug zu machen.</p>',
      },
    },
    failure: {
      title:
        'Leider erfüllen Sie nicht alle notwendigen Voraussetzungen für einen Zuschuss nach THG-Quote',
      content: {
        text: 'Eine Prämie nach THG kann leider nicht geltend gemacht werden, wenn Sie nicht als Halter des Fahrzeuges im Fahrzeugschein eingetragen sind oder Ihr Fahrzeug nicht rein elektrisch betrieben wird.',
        html: 'Gerne schauen wir aber für Sie, ob es noch andere Möglichkeiten für Sie gibt. Sprechen Sie uns an. <a class="gc-text-primary" href="https://www.sw-kassel.de/privatkunden/energie/e-mobilitaet/" target="_blank">Klicken Sie hier</a>, um darüber hinaus weitere Angebote der Städtischen Werke zum Thema E-Mobilität anzusehen.',
      },
    },
  },
  vehicleRegistration: {
    title: 'Fahrzeugzulassung',
    step: {
      label: 'Fahrzeugzulassung',
    },
    formFields: {
      registration: {
        label: 'Sind Sie als Halter im Fahrzeugschein eingetragen?',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
    },
  },
  contactForm: {
    title:
      'Auftrag zur Vermarktung meiner THG-Quote durch die Städtische Werke AG',
    step: {
      label: 'Dateneingabe',
    },
    intro: 'Hiermit übertrage ich,',
    texts: {
      right:
        'der Städtische Werke AG, Königstor 3-13, 34117 Kassel das Recht, für ein E-Auto die THG-Quote geltend zu machen.',
      bonus:
        'Hierfür erhalte ich von der Städtische Werke AG eine jährliche Prämie in Höhe von ',
      transfer:
        'Die Prämie wird von der STW AG auf folgendes Bankkonto überwiesen:',
      vehicleInfo: 'Angaben zum Fahrzeug',
      vehicleRegistration: 'Ihr Fahrzeugschein',
      vehicleRegistrationHint:
        'Bitte laden Sie ein Foto / einen Scan von beiden Seiten Ihres zugehörigen Fahrzeugscheins hoch. Erlaubte Dateitypen: jpg, pdf. Max. 10 MB',
    },
    formFields: {
      customerType: {
        label: 'Kundentyp',
        options: {
          private: {
            label: 'Privatkunde',
          },
          commercial: {
            label: 'Gewerbekunde',
          },
        },
      },
      salutation: {
        label: 'Anrede',
        options: {
          mr: {
            label: 'Herr',
          },
          mrs: {
            label: 'Frau',
          },
          diverse: {
            label: 'Divers',
          },
        },
      },
      firm: {
        label: 'Firma',
      },
      taxNumber: {
        label: 'Steuernummer/Umsatzsteuer-ID-Nr.',
      },
      iban: {
        label: 'IBAN',
        helperDialog: {
          html: '<p>Eine IBAN (International Bank Account Number) ist ein international vereinbarter Code, der in Deutschland aus 22 Zeichen (Buchstaben und Ziffern) besteht:</p><ul class="gc-list-disc gc-mb-4"><li>2-stelliger Ländercode (DE)</li><li>2-stellige Prüfnummer</li><li>8 Stellen für die Bankleitzahl</li><li>10-stelliger Code für die Kontonummer</li></ul><p>z.B. DE89370400440532013000</p>',
        },
      },
      year: {
        label: 'Für das Jahr',
        options: {
          2022: {
            label: '2022',
          },
          2023: {
            label: '2023',
          },
        },
      },
      accountOwner: {
        label: 'Kontoinhaber (falls abweichend)',
      },
      customerNumber: {
        label: 'Kunden- oder Vertragskontonummer',
      },
      carLicense: {
        label: 'Kennzeichen E-PKW',
        helperDialog: {
          html: '<p>Die Eingabe des Kfz-Kennzeichens sollte folgendem Schema entsprechen:<br><strong>XXX YY NNNN E</strong><br><br>Dabei steht <strong>XXX</strong> für 1 bis 3 beliebige Großbuchstaben,<br><strong>YY</strong> für 1 bis 2 beliebige Großbuchstaben und <br><strong>NNNN</strong> für eine maximal 4-stellige Zahl. <br>Führende Nullen sind bei der Zahl nicht zugelassen. <br><strong>E</strong> steht für Elektrofahrzeug.<br><br>Es ist auch erlaubt <strong>XXX-YY NNNN E</strong>.<br>(z.B. KS-SW 123E oder KS-E 1234E)</p>',
        },
      },
      dateOfApproval: {
        label: 'Datum der Zulassung',
        helperDialog: {
          text: 'Das Datum der Zulassung finden Sie in Feld i in Ihrem Fahrzeugschein.',
        },
      },
      vehicleId: {
        label: 'Fahrzeugidentifizierungsnummer',
        helperDialog: {
          text: 'Die 17-stellige Fahrzeugidentifizierungsnummer finden Sie in Feld e in Ihrem Fahrzeugschein.',
        },
      },
      vehicleClass: {
        label: 'Fahrzeugklasse',
        helperDialog: {
          text: 'Die Fahrzeugklasse finden Sie in Feld j in Ihrem Fahrzeugschein.',
        },
      },
      vehicleRegistrationFrontSide: {
        label: 'Vorderseite',
      },
      vehicleRegistrationBackSide: {
        label: 'Rückseite',
      },
      referralCode: {
        label: 'Empfehlungscode',
        helperDialog: {
          text: 'Dieses Feld ist unseren Kooperationspartnern und Resellern vorbehalten. Bitte tragen Sie sie den von uns zugestellten Code hier ein.',
        },
      },
      creditNoteViaEmail: {
        label: 'Gutschriftsbeleg per E-Mail statt per Post',
        headline:
          'Ich möchte meinen Gutschriftsbeleg per E-Mail statt per Post erhalten.',
      },
      assuranceNote: {
        label:
          'Es handelt sich bei dem E-Auto um ein reines Batterieelektrofahrzeug',
        headline:
          'Ich versichere, dass es sich bei dem E-Auto um ein reines Batterieelektrofahrzeug handelt und dass es auf mich als Halter/in zugelassen ist. Zudem versichere ich, dass ich das Recht habe, die THG-Quote geltend zu machen und ich dieses für das aktuelle Kalenderjahr noch an kein anderes Unternehmen und keine andere Person übertragen habe.',
      },
      privacyConfirmation: {
        headline:
          'Ich habe die <a href="https://www.sw-kassel.de/fileadmin/stw/dokumente/2013/emobilitaet/THG_QUOTE_DATENSCHUTZ.pdf" target="_blank">Datenschutzhinweise</a> und <a href="https://www.sw-kassel.de/fileadmin/stw/dokumente/2013/emobilitaet/THG_QUOTE_AGB.pdf" target="_blank">Allgemeinen Geschäftsbedingungen (AGB)</a> zur Kenntnis genommen und bestätige diese hiermit.',
      },
      agreement: {
        label: 'Die Einwilligung zum Speichern von Kontaktformulardaten',
        headline:
          'Ich willige ein, dass meine im Kontaktformular eingegebenen Daten zum Zweck der Kontaktaufnahme mit der Städtische Werke AG übermittelt, verarbeitet und gespeichert werden. Sie können Ihre Einwilligung jederzeit ohne Angabe von Gründen für die Zukunft widerrufen. Entsprechende Details zur Verwendung Ihrer Daten entnehmen Sie unserer <a href="https://www.sw-kassel.de/privatkunden/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
      },
    },
  },
  errorMessages: {
    validators: {
      errorECarLicense: 'Ungültige E-PKW Kennzeichen',
      errorIbanMaxLength: 'IBAN darf max. 22 Zeichen enthalten',
    },
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
