import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormMultipleChoiceField } from '@/lib/forms/builder';

export interface VehicleClassStepContext extends AssistantStepContext {
  class: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormMultipleChoiceField('class', {
      component: {
        columns: 4,
        options: [
          {
            value: 'm1',
          },
          {
            value: 'n1',
          },
          {
            value: 'm3',
          },
          {
            value: 'ml',
          },
        ],
        singleAnswer: true,
      },
      showDescription: true,
      showHelperDialog: true,
      required: true,
    }),
  ],
  hideSkipMessage: () => true,

  id: StepId.VEHICLE_CLASS,
};

export default config;
