import { Buffer } from 'buffer';
import axios, { AxiosRequestHeaders } from 'axios';

import { getApiToken } from './api-token';

export interface FileData {
  filename: string;
  contentType: string;
  content: string;
  encoding: string;
}

interface MailData {
  mailSubject: string;
  mailBody: string;
  mailFromAddress: string;
  mailToAddress: string;
  mailBccAddress?: Array<string>;
  s3BucketName?: string;
  s3FileAttachmentPaths?: Array<string>;
  siteFileAttachments?: Array<FileData>;
}

export const sendMail = async (mailData: MailData): Promise<void> => {
  try {
    let apiToken = '';
    const headers: AxiosRequestHeaders = {};

    const hasToSendToken =
      process.env.VUE_APP_REQUIRES_TOKEN_WHEN_SEND_EMAIL === 'true';

    if (hasToSendToken) {
      // if hasToSendToken is true, we know that VUE_APP_HEADER_NAME_FOR_TOKEN_USED_WHEN_SEND_EMAIL was set
      const headerNameForToken =
        process.env.VUE_APP_HEADER_NAME_FOR_TOKEN_USED_WHEN_SEND_EMAIL;

      const staticToken = process.env.VUE_APP_STATIC_TOKEN_FOR_SEND_EMAIL;

      apiToken = staticToken.length > 0 ? staticToken : await getApiToken();

      headers[headerNameForToken] = apiToken;
    }

    const base64EncodedMailData = Buffer.from(
      JSON.stringify(mailData),
      'utf8',
    ).toString('base64');

    return axios.post(
      `${process.env.VUE_APP_API_URL}/mail`,
      {
        data: base64EncodedMailData,
      },
      {
        headers: headers,
      },
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error('An error occurred while sending the mail.');
  }
};
