// Each step has its own identifier
export enum StepId {
  AVAILABILITY_CHECK = 'availabilityCheck',
  VEHICLE_CLASS = 'vehicleClass',
  VEHICLE_REGISTRATION = 'vehicleRegistration',
  CUSTOMER = 'customer',
  CHECK = 'check',
  CONTACT_FORM = 'contactForm',
  DONE = 'done',
}

// You can optionally divide steps into groups,
// each one having its own identifier
export enum StepCategoryId {}

export enum StepGroupId {}
