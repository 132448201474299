import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormMultipleChoiceField } from '@/lib/forms/builder';

export interface VehicleRegistrationStepContext extends AssistantStepContext {
  isCustomer: FormMultipleChoiceValueType;
  contractNumber: FormTextFieldValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormMultipleChoiceField('registration', {
      component: {
        columns: 2,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),
  ],
  hideSkipMessage: () => true,

  id: StepId.VEHICLE_REGISTRATION,
};

export default config;
