



















































































































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import { AssistantContext } from '~/steps/steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcCol,
    GcForm,
    GcRow,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext as AssistantContext,
      props.step,
    );
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);
    const isCustomerPrivate = ref(true);

    const showRequiredHint = computed<boolean>(
      (): boolean =>
        process.env.VUE_APP_SHOW_REQUIRED_HINT === 'true' &&
        Boolean(
          props.step?.fields &&
            props.step?.fields.some((field) => field.required === true),
        ),
    );

    const bonusValue = computed<string>(() => {
      const [vehicleClass] = props.assistantContext.vehicleClass.class;
      const vehicleClassBonus = {
        m1: '275 €',
        n1: '400 €',
        m3: '9.500 €',
        ml: '275 €',
      };
      return vehicleClassBonus[vehicleClass];
    });
    const onChangeCustomerType = (items) => {
      isCustomerPrivate.value = items.includes('private');
    };

    return {
      bonusValue,
      formData,
      formValid,
      onNext,
      onPrev,
      showRequiredHint,
      isCustomerPrivate,
      onChangeCustomerType,
    };
  },
});
