import { js2xml } from 'xml-js';
import i18n from '@/plugins/i18n';
import { AssistantStepContext } from '@/interfaces/context';

const map = {
  availabilityCheck: {
    driveType: 'Antriebsart',
  },
  contactForm: {
    customerType: 'Privat- oder Gewerbekunde',
    salutation: 'Anrede',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Strasse',
    streetNumber: 'Hausnummer',
    zipcode: 'Postleitzahl',
    city: 'Ort',
    customerNumber: 'Kunden- oder Vertragskontonummer',
    email: 'E-Mail',
    phone: 'Telefon',
    iban: 'IBAN',
    year: 'Kalenderjahr',
    carLicense: 'Kennzeichen',
    dateOfApproval: 'Zulassung',
    vehicleId: 'Fahrzeugidentifizierungsnummer',
    vehicleClass: 'Fahrzeugklasse',
    creditNoteViaEmail: 'eRechnung',
    assuranceNote: 'Vollelektrisch, Halter, Quote',
    privacyConfirmation: 'AGB akzeptiert',
    agreement: 'DSGVO',
    vehicleRegistrationBackSide: 'Fahrzeugscheinvorderseite',
    vehicleRegistrationFrontSide: 'Fahrzeugscheinrückseite',
  },
  customer: {
    isCustomer: 'Kunde',
  },
  vehicleClass: {
    class: 'Fahrzeugklasse',
  },
  vehicleRegistration: {
    registration: 'Halter',
  },
};

const getValue = (ctxValue, stepName?, fieldName?) => {
  if (ctxValue instanceof Array) {
    if (!ctxValue.length) {
      return '';
    } else if (ctxValue[0] instanceof File) {
      return ctxValue.map((file) => file.name).join();
    } else {
      return i18n.t(
        `${stepName}.formFields.${fieldName}.options.${ctxValue[0]}.label`,
      );
    }
  }
  return ctxValue;
};

const prepareJS = (context: AssistantStepContext) =>
  Object.entries(context).reduce(
    (assistentAccumulator, [stepName, stepValue]) => ({
      ...assistentAccumulator,
      ...Object.entries(stepValue as object).reduce(
        (stepAccumulator, [fieldName, fieldValue]) => {
          const mappedKey = map[stepName]?.[fieldName];
          if (mappedKey) {
            return {
              ...stepAccumulator,
              [mappedKey]: getValue(fieldValue, stepName, fieldName),
            };
          }
          return stepAccumulator;
        },
        {},
      ),
    }),
    {},
  );

export const generateXML = (context: AssistantStepContext) => {
  const js = prepareJS(context);
  return js2xml(
    {
      _declaration: {
        _attributes: { version: '1.0', encoding: 'utf-8' },
      },
      ...js,
    },
    {
      compact: true,
      ignoreComment: true,
      spaces: 4,
      //fullTagEmptyElement: true,
    },
  );
};

export const generateTableContent = (context: AssistantStepContext) => {
  const hash = prepareJS(context);
  return Object.entries(hash as { [key: string]: string | boolean }).reduce(
    (accumulator, [key, value]) => {
      const row = `<tr><td>${key}</td><td>${
        typeof value !== 'boolean' ? value : value ? 'Ja' : 'Nein'
      }</td></tr>`;
      return accumulator + row;
    },
    '',
  );
};
