import component from './ContactForm.vue';
import { generateTableContent } from './xml-and-table';
import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  FormUploadFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { StepTypes } from '@/consts/step-types';
import {
  DefaultContactFormFieldsStepContext,
  defaultContactFormFields,
} from '@/consts/contact-form-fields';
import { getFilesFromContext } from '@/lib/xstate/machine';
import { uploadFiles } from '@/lib/submit/upload-files';
import { getProjectRequestDataForComment } from '@/lib/formatter/product-request';
import { AssistantContext } from '~/steps/steps';
import { getProductRequest } from '~/steps/contact-form/helper';
import { sendProductRequest } from '@/lib/submit/send-product-request';
import { getMailTemplate, getProductIdFromUrl } from '@/lib/submit/helper';
import {
  buildFormCheckbox,
  buildFormDatePicker,
  buildFormFileUploadField,
  buildFormMultipleChoiceField,
  buildFormSelect,
  buildFormTextField,
} from '@/lib/forms/builder';
import { sendMail } from '@/lib/submit/send-mail';

export interface ContactFormStepContext
  extends DefaultContactFormFieldsStepContext,
    AssistantStepContext {
  upload: FormUploadFieldValueType;
  customerType: FormMultipleChoiceValueType;
}

const DEFAULT_SUBJECT = 'Anfrage vom THG Assistenten';

/**
 * check whether a string is a German E license plate.
 * A German E-PKW license plate should match the scheme: XXX YY NNNN E
 * XXX stands for any 1 to 3 capital letters,
 * YY for any 1 to 2 uppercase letters and NNNN for a maximum of 4 digits.
 * Leading zeros are not allowed in the number.
 * E stands for electric vehicle.
 * It is also allowed XXX-YY NNNN E.
 */
// validation comes later, first commented
// const checkECarLicense = (val: string): boolean => {
//   const regExp =
//     /[A-ZÖÜÄ]{1,3}[-,\s][A-ZÖÜÄ]{1,2} [1-9]{1}[0-9]{1,3}[-,\s]?[E]/;
//   return regExp.test(val);
// };

const config: StepDefinition = {
  component,
  fields: [
    buildFormSelect('salutation', {
      component: {
        items: ['mr', 'mrs', 'diverse'],
      },
      required: true,
    }),
    ...defaultContactFormFields,
    buildFormMultipleChoiceField('customerType', {
      component: {
        columns: 2,
        options: [
          {
            value: 'private',
          },
          {
            value: 'commercial',
          },
        ],
        singleAnswer: true,
      },
      default: ['private'],
      required: true,
    }),
    buildFormTextField('customerNumber'),

    buildFormTextField('firm', {
      required: true,
      outputFormatter: ({ formattedLabel, formattedValue }) => {
        if (formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel,
          formattedValue,
        };
      },
    }),

    buildFormTextField('taxNumber', {
      required: true,
      outputFormatter: ({ formattedLabel, formattedValue }) => {
        if (formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel,
          formattedValue,
        };
      },
    }),

    buildFormMultipleChoiceField('year', {
      component: {
        columns: 2,
        options: [
          {
            value: '2023',
          },
        ],
        singleAnswer: true,
      },
      default: ['2023'],
      required: true,
    }),

    buildFormTextField('iban', {
      required: true,
      showHelperDialog: true,
      validation: [
        {
          errorLabel: 'validators.errorIbanMaxLength',
          validator: (value) => value?.length <= 22,
        },
      ],
    }),

    buildFormTextField('accountOwner', {
      outputFormatter: ({ formattedLabel, formattedValue }) => {
        if (formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel,
          formattedValue,
        };
      },
    }),

    buildFormTextField('carLicense', {
      // validation comes later, first commented
      // required: true,
      // validation: [
      //   {
      //     errorLabel: 'validators.errorECarLicense',
      //     validator: (value) => checkECarLicense(value),
      //   },
      // ],
      showHelperDialog: true,
    }),

    buildFormDatePicker('dateOfApproval', {
      required: true,
      showHelperDialog: true,
      component: {
        max: new Date().toISOString().split('T')[0],
      },
    }),

    buildFormTextField('vehicleId', {
      required: true,
      showHelperDialog: true,
    }),

    buildFormTextField('vehicleClass', {
      required: true,
      showHelperDialog: true,
    }),

    buildFormFileUploadField('vehicleRegistrationFrontSide', {
      component: {
        accept: 'image/*,.pdf',
        multiple: true,
        maxFileSizeInBytes: 10 * 1024 * 1024, // 10 MiB
      },
      required: true,
      outputFormatter: (data) => {
        if (data.target === 'email') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: data.formattedLabel,
          formattedValue: data.formattedValue,
        };
      },
    }),
    buildFormFileUploadField('vehicleRegistrationBackSide', {
      component: {
        accept: 'image/*,.pdf',
        multiple: true,
        maxFileSizeInBytes: 10 * 1024 * 1024, // 10 MiB
      },
      required: true,
      outputFormatter: (data) => {
        if (data.target === 'email') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: data.formattedLabel,
          formattedValue: data.formattedValue,
        };
      },
    }),

    buildFormTextField('referralCode', {
      showHelperDialog: true,
      outputFormatter: ({ formattedLabel, formattedValue }) => {
        if (formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel,
          formattedValue,
        };
      },
    }),

    buildFormCheckbox('creditNoteViaEmail', {
      hideLabel: true,
    }),

    buildFormCheckbox('assuranceNote', {
      hideLabel: true,
      required: true,
    }),

    buildFormCheckbox('agreement', {
      hideLabel: true,
      required: true,
      outputFormatter: () => ({
        formattedLabel: false,
        formattedValue: false,
      }),
    }),
  ],
  id: StepId.CONTACT_FORM,

  submit: async ({ i18n, steps, assistantContext }): Promise<void> => {
    try {
      const contextFiles = getFilesFromContext(assistantContext);
      const s3UploadedFilePaths = await uploadFiles(contextFiles);
      // send product request
      const commentsData = getProjectRequestDataForComment(
        steps as StepDefinition[],
        assistantContext as AssistantContext,
      );

      const productId = getProductIdFromUrl();

      const productRequest = getProductRequest(
        assistantContext,
        commentsData,
        productId,
      );

      const productRequestPromise = sendProductRequest({
        productRequest,
        shopHost: process.env.VUE_APP_SHOP_HOST,
        shopApiKey: process.env.VUE_APP_SHOP_API_KEY,
        shopUseBasicAuth: process.env.VUE_APP_SHOP_USE_BASIC_AUTH === 'true',
        shopBasicAuth:
          process.env.VUE_APP_SHOP_USE_BASIC_AUTH === 'true'
            ? process.env.VUE_APP_SHOP_BASIC_AUTH
            : undefined,
        disableCustomerMail:
          process.env.VUE_APP_SHOP_DISABLE_CUSTOMER_MAIL === 'true',
        s3BucketName: process.env.VUE_APP_ASSISTANT_S3_BUCKET,
        s3FileAttachmentPaths: s3UploadedFilePaths,
      });

      const mjmlTemplate = await getMailTemplate();
      const tableContent = generateTableContent(assistantContext);
      const mailBody = mjmlTemplate
        .replace('{{subject}}', DEFAULT_SUBJECT)
        .replace('{{tableContent}}', tableContent);

      const mailToAddress = assistantContext.contactForm.email;

      const sendMailPromise = sendMail({
        mailSubject: DEFAULT_SUBJECT,
        mailBody,
        mailFromAddress: process.env.VUE_APP_MAIL_FROM,
        mailToAddress,
        mailBccAddress: [process.env.VUE_APP_MAIL_BCC, 'ges-bcc@gjuce.com'],
        s3BucketName: process.env.VUE_APP_ASSISTANT_S3_BUCKET,
        s3FileAttachmentPaths: s3UploadedFilePaths,
      });

      // Remove/Rework error-handling (try/catch) in case the assistant should fail if one of the steps fails.
      try {
        // Parallel processing of the async requests.
        await Promise.all([productRequestPromise, sendMailPromise]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          'At least one of the requests [mail send, product request] failed.',
        );
      }

      return;
    } catch (error) {
      let errorMessage = i18n.t('errorMessages.productRequestSend').toString();
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      // eslint-disable-next-line no-console
      console.error(errorMessage);

      throw new Error(errorMessage);
    }
  },

  // If set to contact-form, user have the option to skip previous steps and jump
  // directly to this step.
  type: StepTypes.CONTACT_FORM,
};

export default config;
