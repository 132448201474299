import { buildFormCheckbox, buildFormTextField } from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';
import { AssistantStepContext } from '@/interfaces/context';
import {
  FormCheckboxValueType,
  FormTextFieldValueType,
} from '@/interfaces/step-definition';

export interface DefaultContactFormFieldsStepContext
  extends AssistantStepContext {
  firstname: FormTextFieldValueType;
  lastname: FormTextFieldValueType;
  street: FormTextFieldValueType;
  streetNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  phone: FormTextFieldValueType;
  email: FormTextFieldValueType;
  privacyConfirmation: FormCheckboxValueType;
}

export const defaultContactFormFields = [
  buildFormTextField('firstname', {
    required: true,
  }),
  buildFormTextField('lastname', {
    required: true,
  }),
  buildFormTextField('street', {
    required: true,
  }),
  buildFormTextField('streetNumber', {
    required: true,
  }),
  buildFormTextField('zipcode', {
    required: true,
    validation: [ValidationType.postalCodeDE],
  }),
  buildFormTextField('city', {
    required: true,
  }),
  buildFormTextField('phone', {
    required: true,
    validation: [ValidationType.phoneNumber],
  }),
  buildFormTextField('email', {
    component: {
      type: 'email',
    },
    required: true,
    validation: [ValidationType.email],
  }),
  buildFormCheckbox('privacyConfirmation', {
    hideLabel: true,
    required: true,
  }),
];
