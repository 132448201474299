import component from './CheckForm.vue';
import { StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,

  id: StepId.CHECK,
  type: StepTypes.NO_DATA,
};

export default config;
