import { AssistantContext } from '~/steps/steps';
import { ProductRequest } from '@/lib/submit/send-product-request';

export const getProductRequest = (
  assistantContext: AssistantContext,
  comment: string,
  productId: string,
): ProductRequest => {
  const contactFormData = assistantContext.contactForm;

  return <ProductRequest>{
    city: contactFormData.city,
    comment,
    email: contactFormData.email,
    firstName: contactFormData.firstname,
    lastName: contactFormData.lastname,
    phone: contactFormData.phone,
    productId,
    productUrl: `${process.env.VUE_APP_SHOP_HOST}/detail/${productId}`,
    salutationDisplayName:
      contactFormData.salutation === 'Divers' ||
      contactFormData.salutation === ''
        ? 'Keine Angabe'
        : (contactFormData.salutation as ProductRequest['salutationDisplayName']),
    street: `${contactFormData.street} ${contactFormData.streetNumber}`,
    zipCode: contactFormData.zipcode,
  };
};
