import done from '~/steps/done';
import contactForm, {
  ContactFormStepContext,
} from '~/steps/contact-form/index';
import customer, { CustomerStepContext } from '~/steps/customer';
import vehicleRegistration, {
  VehicleRegistrationStepContext,
} from '~/steps/vehicle-registration';
import availabilityCheck, {
  AvailabilityCheckStepContext,
} from '~/steps/availability-check';
import vehicleClass, { VehicleClassStepContext } from '~/steps/vehicle-class';
import check from '~/steps/check';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [
  availabilityCheck,
  vehicleClass,
  vehicleRegistration,
  customer,
  check,
  contactForm,
  done,
];

// Add the imported context interface for the steps
export interface AssistantContext {
  availabilityCheck: AvailabilityCheckStepContext;
  vehicleClass: VehicleClassStepContext;
  vehicleRegistration: VehicleRegistrationStepContext;
  customer: CustomerStepContext;
  contactForm: ContactFormStepContext;
}

export default steps;
