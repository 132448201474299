import { Buffer } from 'buffer';
import axios from 'axios';
import { FileData } from '@/lib/submit/send-mail';

const MAIL_TEMPLATE_DIRECTORY = 'mail-templates';

export const getMailTemplate = async (
  mailTemplateFileName = '',
): Promise<string> => {
  const mailTemplateFilePath = `${MAIL_TEMPLATE_DIRECTORY}/${
    mailTemplateFileName === ''
      ? process.env.VUE_APP_TENANT_ACRONYM
      : mailTemplateFileName
  }.html`;

  try {
    return (
      await axios.get(`${process.env.VUE_APP_BASE_URL}/${mailTemplateFilePath}`)
    ).data as string;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    throw new Error(
      `Could not load mail template "${process.env.VUE_APP_BASE_URL}/${mailTemplateFilePath}"`,
    );
  }
};

export const getFileAttachments = async (
  attachments: Array<string>,
): Promise<Array<FileData>> => {
  let files: Array<FileData | Record<string, unknown>> = [];

  files = await Promise.all(
    attachments.map(
      async (
        attachment: string,
      ): Promise<FileData | Record<string, unknown>> => {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/${attachment}`,
            { responseType: 'arraybuffer' },
          );

          const fileAttachmentContentType = response.headers['content-type'];

          const fileAttachmentData = Buffer.from(
            response.data,
            'binary',
          ).toString('base64');

          return {
            filename: attachment.split('/').pop() ?? '',
            contentType: fileAttachmentContentType,
            content: fileAttachmentData,
            encoding: 'base64',
          };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          // eslint-disable-next-line no-console
          console.error(
            `Could not get file "${process.env.VUE_APP_BASE_URL}/${attachment}"`,
          );

          return {};
        }
      },
    ),
  );

  const filteredFiles: Array<FileData> = files.filter((file) =>
    Object.hasOwnProperty.call(file, 'filename'),
  ) as Array<FileData>;

  return filteredFiles;
};

export const getProductIdFromUrl = () => {
  const defaultProductId = process.env.VUE_APP_SHOP_PRODUCT_ID;
  const url = new URL(window.location.href);

  return url.searchParams.get('product-id') ?? defaultProductId;
};
